import { IDomFacade } from '@airelogic/fontoxpath';
import { Attr, CharacterData, Element, Node } from 'slimdom';

const extractName = (bucket?: string | null) => {
  return bucket?.substring('name-'.length); //name-{nodename}
};

//This essentially creates the requested nodes/attributes on the fly as we dont have a document to run the xpath against
export const validationDomFacade: IDomFacade = {
  getAllAttributes: function (node: Element, bucket?: string | null): Attr[] {
    const newName = extractName(bucket);

    if (newName === undefined || node.nodeName === newName) return [];

    return [new Attr(null, null, newName, '', null)];
  },
  getAttribute: function (_node: Element, _attributeName: string): string | null {
    return null;
  },
  getChildNodes: function (node: Node, bucket?: string | null): Node[] {
    const newName = extractName(bucket);
    if (newName === undefined || node.nodeName === newName) return [];

    return [new Element(null, null, newName)];
  },
  getData: function (_node: Attr | CharacterData): string {
    return '';
  },
  getFirstChild: function (_node: Node, _bucket?: string | null): Node | null {
    return null;
  },
  getLastChild: function (node: Node, _bucket?: string | null): Node | null {
    return node;
  },
  getNextSibling: function (_node: Node, _bucket?: string | null): Node | null {
    return null;
  },
  getParentNode: function (node: Node, bucket?: string | null): Node | null {
    const newName = extractName(bucket);
    if (newName === undefined || node.nodeName === newName) {
      return new Element(null, null, 'parent');
    }

    return new Element(null, null, newName);
  },
  getPreviousSibling: function (_node: Node, _bucket?: string | null): Node | null {
    return null;
  },
};
