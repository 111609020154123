import { ConfirmationDialog, IconButton, When } from '@airelogic/form-management/components';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid2 as Grid, Paper, TextField, Typography } from '@mui/material';

import { useMemo, useRef, useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import useOnClickOutside from 'use-onclickoutside';
import { FormValues } from '../../Form';
import { useSectionSettingsContext } from '../../SectionSettingsContext';
import {
  makeSelectControlCountInSection,
  makeSelectOrderedGridsInSection,
} from '../../StateManagement/Selectors/GridSelector';
import {
  makeIsSelectedSection,
  selectCanDeleteSection,
} from '../../StateManagement/Selectors/SectionSelector';
import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import {
  deleteSection,
  moveSection,
  updateSelectedComponent,
} from '../../StateManagement/layoutSlice';
import GridFactory from './GridFactory';
import { useStyles } from './Section.styles';
interface Props {
  sectionId: string;
}

const Section = ({ sectionId }: Props) => {
  const selectSectionGridIds = useMemo(
    () => makeSelectOrderedGridsInSection(sectionId),
    [sectionId],
  );
  const selectControlsCount = useMemo(
    () => makeSelectControlCountInSection(sectionId),
    [sectionId],
  );
  const selectIsSelectedSection = useMemo(() => makeIsSelectedSection(sectionId), [sectionId]);

  const isSelectedSection = useAppSelector(selectIsSelectedSection);
  const grids = useAppSelector(selectSectionGridIds);
  const controlsCount = useAppSelector(selectControlsCount);
  const canDeleteSection = useAppSelector(selectCanDeleteSection);

  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const { classes, cx } = useStyles();
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [titleEdit, setTitleEdit] = useState(false);
  useOnClickOutside(ref, () => setTitleEdit(false));

  const { errors } = useFormState<FormValues>();
  const { control, register } = useFormContext<FormValues>();

  const sectionSettings = useSectionSettingsContext();
  const index = sectionSettings.findIndex((f) => f.id === sectionId);
  const hasErrors = errors.sectionSettings?.[index] !== undefined;

  const sectionTitle = useWatch({ control, name: `sectionSettings.${index}.basicSettings.label` });

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    onDeleteSection();
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const onDeleteSection = () => {
    dispatch(deleteSection({ sectionId }));
  };

  const handleSectionSettingsClick = () => {
    dispatch(updateSelectedComponent({ id: sectionId, type: 'section' }));
  };

  const handleSectionMoveUp = () => {
    dispatch(moveSection({ sectionId, direction: 'up' }));
  };

  const handleSectionMoveDown = () => {
    dispatch(moveSection({ sectionId, direction: 'down' }));
  };

  const onDelete = () => {
    controlsCount > 0 ? setShowConfirmDelete(true) : onDeleteSection();
  };

  const onTitleKeyDown = (key: string) => {
    if (key === 'Enter') {
      setTitleEdit(false);
    }
  };

  return (
    <>
      <Paper
        className={cx(classes.root, {
          [classes.rootActive]: isSelectedSection,
        })}
        data-testid={`section-${sectionId}`}
        data-haserror={hasErrors}
        data-componenttype="section"
        data-componentid={sectionId}
      >
        <Grid container className={classes.sectionHeader}>
          <Grid
            onClick={() => setTitleEdit(true)}
            className={classes.sectionHeaderText}
            size="grow"
          >
            {titleEdit ? (
              <div ref={ref}>
                <TextField
                  id={`section-${sectionId}-title`}
                  className={classes.editTitleField}
                  type="text"
                  label="Title"
                  onKeyDown={(e) => onTitleKeyDown(e.key)}
                  {...register(`sectionSettings.${index}.basicSettings.label`)}
                  autoFocus
                />
              </div>
            ) : (
              <Typography variant="h5">{sectionTitle === '' ? '\u00A0' : sectionTitle}</Typography>
            )}
          </Grid>

          <Grid className={classes.actionButtons} size="grow">
            <When condition={canDeleteSection}>
              <IconButton tooltipText="Move section down" onClick={handleSectionMoveDown}>
                <ArrowDownward />
              </IconButton>
              <IconButton tooltipText="Move section up" onClick={handleSectionMoveUp}>
                <ArrowUpward />
              </IconButton>
              <IconButton tooltipText="Delete section" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </When>
            <IconButton tooltipText="Display section settings" onClick={handleSectionSettingsClick}>
              <SettingsIcon color={hasErrors ? 'error' : 'secondary'} />
            </IconButton>
          </Grid>
        </Grid>
        <div className={classes.body}>
          {grids.map((gridId) => (
            <GridFactory key={gridId} gridId={gridId} />
          ))}
        </div>
      </Paper>
      <ConfirmationDialog
        data-testid={`deleteSectionPrompt-${sectionId}`}
        confirmationText={`Are you sure you want to delete the current section? ${controlsCount} control(s) will be deleted.`}
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </>
  );
};

export default Section;
