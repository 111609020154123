import { TextInput, When } from '@airelogic/form-management/components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorIcon from '@mui/icons-material/Error';
import UpdateIcon from '@mui/icons-material/Update';
import { Box, ButtonBase, Grid2 as Grid, Tooltip, Typography } from '@mui/material';
import { TabProps } from '@mui/material/Tab';
import React, { forwardRef, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { BuildingBlockVersionData, FormSchema } from '../FormBuildingInterfaces';
import PageEditMenu from './PageEditMenu';

const useStyles = makeStyles()(() => ({
  icon: {
    color: '#3463FD',
    fontSize: 20,
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  tabText: {
    color: '#33444E',
    fontSize: '16px',
    whiteSpace: 'normal',
    overflow: 'hidden',
  },
  selected: {
    borderLeft: '3px solid',
    borderLeftColor: '#3463FD',
    background: '#EDF0F2',
  },
  unselected: {
    borderLeft: '3px solid',
    borderLeftColor: '#FFFFFF',
  },
  invisibleBox: {
    display: 'none',
  },
  errorIcon: {
    color: 'red',
    fontSize: 20,
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  updateIconBox: {
    marginRight: '8px',
    marginTop: '13px',
  },
}));

export type FormsTabProps = TabProps & {
  index: number;
  onRemove: (index: number) => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  isLastTab: boolean;
  blockVersions: BuildingBlockVersionData[];
  selected: boolean;
  onChange?: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
};

export const FormsTab = forwardRef<HTMLButtonElement, FormsTabProps>(
  (
    {
      onChange,
      index,
      value,
      onRemove,
      onMoveUp,
      onMoveDown,
      isLastTab,
      blockVersions,
      selected,
    }: FormsTabProps,
    ref,
  ) => {
    const { classes } = useStyles();
    const {
      control,
      formState: { errors },
    } = useFormContext<FormSchema>();

    const pageTitleWatch = useWatch({
      control,
      name: `pages.${index}.title`,
    });

    const [renderMode, setRenderMode] = React.useState<'input' | 'typography'>(
      pageTitleWatch === '' ? 'input' : 'typography',
    );

    const [pageHasBlockUpdates, setPageHasBlockUpdates] = React.useState<boolean>(false);

    const onEditPage = () => {
      setRenderMode('input');
    };

    const pageBuildingBlocks = useWatch({
      control,
      name: `pages.${index}.buildingBlocks`,
    });

    useEffect(() => {
      const hasUpdates = pageBuildingBlocks.some((bb) => {
        const availableBlockVersions = blockVersions?.find((x) => x.key === bb.key)?.versions ?? [];
        return availableBlockVersions.length > bb.version;
      });
      setPageHasBlockUpdates(hasUpdates);
    }, [pageBuildingBlocks, setPageHasBlockUpdates, blockVersions]);

    return (
      <ButtonBase
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          if (!selected && onChange) {
            onChange(event, value);
          }
        }}
        ref={ref}
        {...(selected ? { className: classes.selected } : { className: classes.unselected })}
      >
        <Grid container width={'100%'}>
          <Grid size="auto">
            <PageEditMenu
              index={index}
              onRemove={onRemove}
              disableRemove={isLastTab}
              onMoveUp={onMoveUp}
              onMoveDown={onMoveDown}
              onEditPage={onEditPage}
            ></PageEditMenu>
          </Grid>
          <Grid size="grow">
            <Controller
              control={control}
              name={`pages.${index}.title`}
              defaultValue={pageTitleWatch}
              render={({ field }) => (
                <>
                  {renderMode === 'input' ? (
                    <TextInput
                      id={`pages.${index}.title`}
                      onChange={field.onChange}
                      onBlur={() => {
                        field.onBlur();
                        setRenderMode('typography');
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          field.onBlur();
                          setRenderMode('typography');
                        }
                      }}
                      ref={field.ref}
                      defaultValue={pageTitleWatch}
                      required
                      label="Page title"
                      type="text"
                      multiline
                      rows={3}
                      fullWidth
                    />
                  ) : (
                    <Box
                      display="flex"
                      justifyContent={'left'}
                      alignItems={'center'}
                      height="100%"
                      textAlign={'left'}
                    >
                      <Typography className={classes.tabText}>{pageTitleWatch}</Typography>
                    </Box>
                  )}
                </>
              )}
            />
          </Grid>
          <When condition={pageHasBlockUpdates}>
            <Box className={classes.updateIconBox}>
              <UpdateIcon titleAccess="Update available" />
            </Box>
          </When>
          <Grid size="auto">
            <Box display="flex" justifyContent={'left'} alignItems={'center'} height="100%">
              {errors?.pages?.[index]?.title !== undefined ? (
                <Tooltip title={'The page title must not be empty'}>
                  <ErrorIcon className={classes.errorIcon}></ErrorIcon>
                </Tooltip>
              ) : errors?.pages?.[index] !== undefined ? (
                <Tooltip title={'There is a validation error on this page'}>
                  <ErrorIcon className={classes.errorIcon}></ErrorIcon>
                </Tooltip>
              ) : selected ? (
                <ArrowForwardIcon className={classes.icon}></ArrowForwardIcon>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </ButtonBase>
    );
  },
);
export default FormsTab;
