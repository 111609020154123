import { createSelector } from '@reduxjs/toolkit';
import {
  getHoveredCell,
  getLocalState,
  getSelectedCell,
  getSelectedComponent,
} from '../localSelectors';
import { Cell } from '../types';

const selectSelectedCell = createSelector(getLocalState, getSelectedCell);

export const makeIsSelectedCell = (cell: Cell) =>
  createSelector([selectSelectedCell], (selectedCell) =>
    selectedCell
      ? selectedCell.gridId === cell.gridId &&
        selectedCell.rowId === cell.rowId &&
        selectedCell.columnId === cell.columnId
      : false,
  );

export const selectSelectedComponent = createSelector(getLocalState, getSelectedComponent);

export const selectCurrentlyHoveredCell = createSelector(getLocalState, getHoveredCell);

export const makeIsRowHovered = (rowId: string) =>
  createSelector([selectCurrentlyHoveredCell], (cell) => cell?.rowId === rowId);

export const makeIsColumnHovered = (columnId: string) =>
  createSelector([selectCurrentlyHoveredCell], (cell) => cell?.columnId === columnId);
