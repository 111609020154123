import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../Form';
import { makeSelectControl } from '../../StateManagement/Selectors/ControlSelector';
import { useAppSelector } from '../../StateManagement/hooks';
import { TabKeys } from '../HeaderTabs';
import OptionsResolver from './AdditionalSettings/OptionsResolver';
import BasicSettings from './BasicSettings/BasicSettings';
import HelpMessage from './BasicSettings/HelpMessage';
import NotificationSettings from './BasicSettings/NotificationSettings';
import RepeatedControlDefaultValues from './BasicSettings/RepeatedControlDefaultValues';
import VisibilityOptions from './BasicSettings/VisibilityOptions';
import Formulas from './Formulas/Formulas';
import ValidationAndAlerts from './ValidationAndAlerts/ValidationAndAlerts';

interface Props {
  fieldIndex: number;
  tab: TabKeys;
}

const TabResolver = ({ fieldIndex, tab }: Props) => {
  const { getValues } = useFormContext<FormValues>();
  const controlId = getValues(`controlSettings.${fieldIndex}.id`);
  const selectComponent = useMemo(() => makeSelectControl(controlId), [controlId]);
  const component = useAppSelector(selectComponent);

  if (component === undefined) {
    throw Error(`Unable to find control id ${controlId}`);
  }

  switch (tab) {
    case 'basicSettings':
      return (
        <>
          <BasicSettings fieldIndex={fieldIndex} gridId={component.cell.gridId} />
          <VisibilityOptions fieldIndex={fieldIndex} />
          <HelpMessage fieldIndex={fieldIndex} />
          <NotificationSettings fieldIndex={fieldIndex} />
          <RepeatedControlDefaultValues fieldIndex={fieldIndex} gridId={component.cell.gridId} />
        </>
      );
    case 'validation':
      return <ValidationAndAlerts fieldIndex={fieldIndex} />;
    case 'formula':
      return <Formulas fieldIndex={fieldIndex} />;
    case 'additionalSettings':
      return <OptionsResolver fieldIndex={fieldIndex} />;
    default:
      return null;
  }
};

export default TabResolver;
