import HelpIcon from '@mui/icons-material/Help';
import { Tooltip, TooltipProps } from '@mui/material';
import { cloneElement, ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  generalIcon: {
    color: '#889AA5',
  },
}));

const InputTooltip = (props: Omit<TooltipProps, 'children'> & { children?: ReactElement }) => {
  const { classes } = useStyles();

  if (props.children) {
    return (
      <Tooltip {...props}>
        {cloneElement(props.children, { className: classes.generalIcon })}
      </Tooltip>
    );
  } else {
    return (
      <Tooltip {...props}>
        <HelpIcon className={classes.generalIcon}></HelpIcon>
      </Tooltip>
    );
  }
};

export default InputTooltip;
