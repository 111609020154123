import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { repeatedDefaultValue } from '../../Controls/DefaultSettings';
import { FormValues } from '../../Form';
import { makeSelectControlIdsForGrid } from '../../StateManagement/Selectors/ControlSelector';
import { useAppSelector } from '../../StateManagement/hooks';

interface Props {
  gridIterationIds: string[] | undefined;
  gridId: string;
}

interface ItemToMove {
  fromIndex: number;
  toIndex: number;
}

type RepeatableDefaultValue = z.infer<typeof repeatedDefaultValue>;
export interface AffectedControl {
  index: number;
  newRepeatableDefaultValues: RepeatableDefaultValue[];
}

export const useRepeatedDefaultValues = ({ gridIterationIds, gridId }: Props) => {
  const selectGridControls = useMemo(() => makeSelectControlIdsForGrid(gridId), [gridId]);
  const gridControlIds = useAppSelector(selectGridControls);

  const { getValues } = useFormContext<FormValues>();
  const allControlFields = getValues(`controlSettings`);
  const affectedControls: AffectedControl[] = [];

  for (const [index, controlField] of allControlFields.entries()) {
    if (
      controlField.basicSettings.repeatableDefaultValues &&
      gridIterationIds !== undefined &&
      gridControlIds.includes(controlField.id)
    ) {
      const repeatableDefaultValues = controlField.basicSettings.repeatableDefaultValues;

      const itemToAdd = gridIterationIds.filter(
        (x) => !repeatableDefaultValues.map((x) => x.iterationId).includes(x),
      )[0];

      const itemToRemove = repeatableDefaultValues
        .map((x) => x.iterationId)
        .filter((x) => !gridIterationIds.includes(x))[0];

      const itemToMove = getItemToMove(
        gridIterationIds,
        repeatableDefaultValues.map((x) => x.iterationId),
      );

      if (itemToAdd) {
        const itemToAddIndex = gridIterationIds.indexOf(itemToAdd);
        repeatableDefaultValues.splice(itemToAddIndex, 0, {
          iterationId: itemToAdd,
          defaultValue: '',
        });
      } else if (itemToRemove) {
        const removeIndex = repeatableDefaultValues.map((x) => x.iterationId).indexOf(itemToRemove);
        repeatableDefaultValues.splice(removeIndex, 1);
      } else if (itemToMove && ~itemToMove.toIndex) {
        const element = repeatableDefaultValues[itemToMove.fromIndex];
        repeatableDefaultValues.splice(itemToMove.fromIndex, 1);
        repeatableDefaultValues.splice(itemToMove.toIndex, 0, element);
      }

      const newAffectedControl: AffectedControl = {
        index: index,
        newRepeatableDefaultValues: repeatableDefaultValues,
      };
      affectedControls.push(newAffectedControl);
    }
  }

  return { affectedControls } as const;
};

const getItemToMove = (
  gridIterationIds: string[],
  fieldIterationIds: string[],
): ItemToMove | undefined => {
  for (let i = 0; i < gridIterationIds.length; i++) {
    if (gridIterationIds[i] !== fieldIterationIds[i]) {
      const fromIndex = gridIterationIds.indexOf(gridIterationIds[i]);
      const toIndex = gridIterationIds.indexOf(fieldIterationIds[i]);
      return {
        fromIndex: fromIndex,
        toIndex: toIndex,
      };
    }
  }
  return undefined;
};
