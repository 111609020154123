import Actions from './ReferenceDataTableActions';
import { useGetAllReferenceData } from './Service';
import { ExistingReferenceData } from './Types';

import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useSessionStorage } from '@uidotdev/usehooks';
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';

const createSessionStorageKey = (itemKey: string) => `referenceDataTable.v2.${itemKey}`;

const Table = () => {
  const { data, isLoading, isRefetching, refetch, isError } = useGetAllReferenceData();

  const [globalFilter, setGlobalFilter] = useSessionStorage(
    createSessionStorageKey('globalFilter'),
    '',
  );

  const columns = useMemo<MRT_ColumnDef<ExistingReferenceData>[]>(
    () => [
      {
        header: 'Key',
        accessorKey: 'key',
      },
      {
        header: 'Version',
        accessorKey: 'version',
        size: 100,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnOrdering: false,
    enableHiding: false,
    enableMultiSort: false,
    enableGrouping: false,
    initialState: {
      showGlobalFilter: true,
      sorting: [{ id: 'key', desc: false }],
    },
    state: {
      isLoading,
      globalFilter,
      showProgressBars: isRefetching,
      showAlertBanner: isError,
    },
    defaultColumn: {
      enableColumnFilter: false,
      enableGlobalFilter: true,
    },
    mrtTheme: () => ({
      baseBackgroundColor: '#fff',
    }),
    muiTopToolbarProps: {
      sx: (theme) => ({
        padding: theme.spacing(1),
      }),
    },
    muiSearchTextFieldProps: {
      variant: 'standard',
      inputProps: {
        'aria-label': 'Search',
      },
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    renderTopToolbarCustomActions: () => <Typography variant="h6">Reference data</Typography>,
    renderToolbarInternalActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    enableRowActions: true,
    positionActionsColumn: 'last',
    globalFilterFn: 'contains',
    renderRowActions: ({ row }) => <Actions referenceData={row.original} />,
    onGlobalFilterChange: setGlobalFilter,
  });

  return <MaterialReactTable table={table} />;
};
export default Table;
