import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import {
  canAddColumnToGrid,
  canDeleteColumnInGrid,
  canDeleteGrid,
  canDeleteRowInGrid,
  canSplitGrid,
  getAllGrids,
  getCellsInRow,
  getGridById,
  getGridIdsInSection,
  getLocalState,
  getSelectedGrid,
  isSelectedGrid,
} from '../localSelectors';
import { RootState } from '../store';
import { Grid, IControl } from '../types';
import { selectAllControls } from './ControlSelector';

const selectGridById = (gridId: string) =>
  createSelector([getLocalState], (state) => getGridById(state, gridId));

export const makeSelectColumnsForGrid = (gridId: string) =>
  createSelector([selectGridById(gridId)], (grid) => grid.columnIds);

export const makeSelectRowsForGrid = (gridId: string) =>
  createSelector([selectGridById(gridId)], (grid) => grid.rowIds);

export const makeCanDeleteRowInGrid = (gridId: string) =>
  createSelector([selectGridById(gridId)], canDeleteRowInGrid);

export const makeCanAddColumnToGrid = (gridId: string) =>
  createSelector([selectGridById(gridId)], canAddColumnToGrid);

export const makeCanDeleteColumnInGrid = (gridId: string) =>
  createSelector([selectGridById(gridId)], canDeleteColumnInGrid);

const selectAllGrids = createSelector(getLocalState, getAllGrids);

export const makeSelectControlCountInSection = (sectionId: string) =>
  createSelector([selectAllGrids, selectAllControls], (grids, components) =>
    grids
      .filter((g) => g.sectionId === sectionId)
      .reduce(
        (runningTotal, gridId) => runningTotal + selectControlCountInGrid(gridId, components),
        0,
      ),
  );

export const makeSelectCellsInRow = (gridId: string, rowId: string) =>
  createSelector([getLocalState], (state) => getCellsInRow(state, gridId, rowId));

export const makeSelectOrderedGridsInSection = (sectionId: string) =>
  createSelector([getLocalState, (_: RootState) => sectionId], getGridIdsInSection, {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  });

const selectComponentCountInRow = (components: IControl[], rowId: string) =>
  components.filter((c) => c.cell.rowId === rowId).length;

export const makeSelectComponentCountInRow = (rowId: string) =>
  createSelector([selectAllControls, (_: RootState) => rowId], selectComponentCountInRow);

export const makeSelectControlCountInColumn = (columnId: string) =>
  createSelector(
    [selectAllControls, (_: RootState) => columnId],
    (controls, columnId) => controls.filter((c) => c.cell.columnId === columnId).length,
  );

const selectControlCountInGrid = (grid: Grid, components: IControl[]) =>
  grid.rowIds.reduce(
    (runningTotal, rowId) => runningTotal + selectComponentCountInRow(components, rowId),
    0,
  );

export const makeSelectControlCountInGrid = (gridId: string) =>
  createSelector([selectGridById(gridId), selectAllControls], selectControlCountInGrid);

export const makeSelectRepeatingGridIndex = (gridId: string) =>
  createSelector(
    [(_: RootState) => gridId, selectAllRepeatingGridIds],
    (gridId, allRepeatingGridIds) => allRepeatingGridIds.findIndex((id) => id === gridId),
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  );

export const makeIsRepeatingGrid = (gridId: string) =>
  createSelector(
    [(_: RootState) => gridId, selectAllRepeatingGridIds],
    (gridId, allRepeatingGridIds) => allRepeatingGridIds.findIndex((id) => id === gridId) !== -1,
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  );

export const makeSelectCanDeleteGrid = (gridId: string) =>
  createSelector([getLocalState], (state) => canDeleteGrid(state, gridId));

export const selectAllRepeatingGridIds = createSelector(
  [selectAllGrids],
  (grids) => grids.filter((g) => g.isRepeated === true).map((g) => g.id),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

export const selectSelectedRepeat = createSelector(getLocalState, getSelectedGrid);

export const makeSelectGridById = (gridId: string) => selectGridById(gridId);

export const makeIsSelectedGrid = (gridId: string) =>
  createSelector([getLocalState], (state) => isSelectedGrid(state, gridId));

export const makeSelectCanSplitGridSelector = (gridId: string, rowId: string) =>
  createSelector([getLocalState], (state) => canSplitGrid(state, gridId, rowId));
