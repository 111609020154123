import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useSessionStorage } from '@uidotdev/usehooks';
import { format } from 'date-fns';
import {
  MRT_Cell,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { BuildingBlock, ImportStatus } from './BuildingBlock';
import { useGetAvailableBuildingBlocks } from './Service';
import BuildingBlockActions from './TableActions/BuildingBlockActions';

const importStatusLookup: Record<ImportStatus, string> = {
  UNKNOWN: 'Duplicate block key found',
  NOT_IMPORTED: 'New',
  UPDATE_AVAILABLE: 'Update available',
  UP_TO_DATE: 'Up to date',
};

const createSessionStorageKey = (itemKey: string) => `buildingBlockTable.v2.${itemKey}`;

const BuildingBlocksTable = () => {
  const [columnFilters, setColumnFilters] = useSessionStorage<MRT_ColumnFiltersState>(
    createSessionStorageKey('columnFilters'),
    [],
  );
  const [globalFilter, setGlobalFilter] = useSessionStorage(
    createSessionStorageKey('globalFilter'),
    '',
  );
  const [sorting, setSorting] = useSessionStorage<MRT_SortingState>(
    createSessionStorageKey('sorting'),
    [
      {
        id: 'lastModifiedDate',
        desc: true,
      },
    ],
  );
  const [pagination, setPagination] = useSessionStorage<MRT_PaginationState>(
    createSessionStorageKey('paging'),
    {
      pageIndex: 0,
      pageSize: 10,
    },
  );

  const { data, isLoading, refetch, isError, isRefetching } = useGetAvailableBuildingBlocks({
    sorting: sorting?.length > 0 ? sorting[0] : undefined,
    pagination,
    globalFilter,
    columnFilters,
  });

  const columns = useMemo<MRT_ColumnDef<BuildingBlock>[]>(
    () => [
      {
        header: 'Key',
        accessorKey: 'blockKey',
        enableColumnFilter: true,
      },
      {
        header: 'Version',
        accessorKey: 'latestVersion',
        size: 100,
        enableSorting: false,
      },
      {
        header: 'Title',
        accessorKey: 'title',
      },
      {
        header: 'Application',
        accessorKey: 'applicationName',
        enableColumnFilter: true,
      },
      {
        header: 'Last modified',
        accessorKey: 'lastModifiedDate',
        Cell: ({ cell }: { cell: MRT_Cell<BuildingBlock> }) => {
          return <>{format(new Date(cell.getValue<string>()), 'dd/MM/yyyy')}</>;
        },
        size: 100,
      },
      {
        header: 'Status',
        accessorKey: 'importStatus',
        Cell: ({ cell }: { cell: MRT_Cell<BuildingBlock> }) => {
          return <>{importStatusLookup[cell.getValue<ImportStatus>()]}</>;
        },
        size: 100,
        enableSorting: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: data?.filteredItems ?? [],
    rowCount: data?.totalItems ?? 0,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnOrdering: false,
    enableHiding: false,
    enableMultiSort: false,
    enableGrouping: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
    },
    state: {
      isLoading,
      columnFilters,
      globalFilter,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    defaultColumn: {
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    mrtTheme: () => ({
      baseBackgroundColor: '#fff',
    }),
    muiTopToolbarProps: {
      sx: (theme) => ({
        padding: theme.spacing(1),
      }),
    },
    muiSearchTextFieldProps: {
      variant: 'standard',
      inputProps: {
        'aria-label': 'Search',
      },
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    renderTopToolbarCustomActions: () => <Typography variant="h6">Building Blocks</Typography>,
    renderToolbarInternalActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => <BuildingBlockActions buildingBlock={row.original} />,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
  });

  return <MaterialReactTable table={table} />;
};

export default BuildingBlocksTable;
