import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import StyledButton from '../Buttons/StyledButton';

const useStyles = makeStyles()(() => ({
  dialogHeader: {
    backgroundColor: '#251430', //theme.palette.primary.main,
    color: '#FFFFFF', //theme.palette.primary.contrastText,
  },
}));

interface ConfirmationDialogRawProps {
  confirmationText?: React.ReactNode;
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  positiveChoice?: string;
  negativeChoice?: string;
}

export const ConfirmationDialog = (
  props: ConfirmationDialogRawProps & Omit<DialogProps, 'variant' | 'margin' | 'ref' | 'inputRef'>,
) => {
  const {
    open,
    handleConfirm,
    handleCancel,
    confirmationText = 'Are you sure?',
    positiveChoice = 'Yes',
    negativeChoice = 'Cancel',
    ...rest
  } = props;
  const { classes } = useStyles();
  return (
    <Dialog maxWidth="md" aria-labelledby="confirmation-dialog-title" open={open} {...rest}>
      <DialogTitle id="confirmation-dialog-title" className={classes.dialogHeader}>
        Confirmation Required
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" component="span">
          {confirmationText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton autoFocus onClick={handleCancel}>
          {negativeChoice}
        </StyledButton>
        <StyledButton onClick={handleConfirm}>{positiveChoice}</StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
