import { ConfirmationDialog, IconButton, When } from '@airelogic/form-management/components';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid2 as MuiGrid } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  makeSelectCanDeleteGrid,
  makeSelectControlCountInGrid,
} from '../../StateManagement/Selectors/GridSelector';
import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import { deleteGrid, moveGrid } from '../../StateManagement/layoutSlice';
import { NonRepeatingGrid } from '../../StateManagement/types';
import ColumnOptions from './ColumnOptions';
import { useStyles } from './Grid.styles';
import Row from './Row';
import RowOptions from './RowOptions';

const Grid = (grid: NonRepeatingGrid) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const selectComponentCount = useMemo(() => makeSelectControlCountInGrid(grid.id), [grid.id]);
  const selectCanDeleteGrid = useMemo(() => makeSelectCanDeleteGrid(grid.id), [grid.id]);

  const canDeleteGrid = useAppSelector(selectCanDeleteGrid);
  const controlsCount = useAppSelector(selectComponentCount);

  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    handleDeleteGrid();
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const handleDeleteGrid = () => {
    dispatch(deleteGrid({ gridId: grid.id }));
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onDeleteGrid = () => {
    controlsCount > 0 ? setShowConfirmDelete(true) : handleDeleteGrid();
  };

  const handleGridMoveDown = () => {
    dispatch(moveGrid({ gridId: grid.id, direction: 'down' }));
  };

  const handleGridMoveUp = () => {
    dispatch(moveGrid({ gridId: grid.id, direction: 'up' }));
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classes.grid}
      data-testid={`grid-${grid.id}`}
    >
      <MuiGrid container className={classes.actionButtons} style={{ height: '24px' }}>
        <When condition={isHovered && canDeleteGrid}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton tooltipText="Move grid down" onClick={handleGridMoveDown}>
              <ArrowDownward />
            </IconButton>
            <IconButton tooltipText="Move grid up" onClick={handleGridMoveUp}>
              <ArrowUpward />
            </IconButton>
            <IconButton tooltipText="Delete grid" onClick={onDeleteGrid}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </When>
      </MuiGrid>
      <MuiGrid container>
        <Box flex={1}>
          <MuiGrid container>
            <Box width={'24px'} />
            {grid.columnIds.map((columnId) => {
              return <ColumnOptions key={columnId} gridId={grid.id} columnId={columnId} />;
            })}
          </MuiGrid>
        </Box>
      </MuiGrid>

      {grid.rowIds.map((rowId) => {
        return (
          <MuiGrid key={rowId} container>
            <RowOptions gridId={grid.id} rowId={rowId} />
            <Box flex={1}>
              <MuiGrid container>
                <Row gridId={grid.id} rowId={rowId} />
              </MuiGrid>
            </Box>
          </MuiGrid>
        );
      })}
      <ConfirmationDialog
        data-testid={`deleteGridPrompt-${grid.id}`}
        confirmationText={`Are you sure you want to delete the current grid? ${controlsCount} control(s) will be deleted.`}
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </div>
  );
};
export default Grid;
