import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));

export interface IconButtonProps extends Omit<MuiIconButtonProps, 'component'> {
  tooltipText?: string;
}

const IconButton = (props: IconButtonProps) => {
  const { classes, cx } = useStyles();
  const { tooltipText = '', className, ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <span>
        <MuiIconButton
          className={cx(classes.root, className)}
          color="secondary"
          {...rest}
          aria-label={tooltipText}
        >
          {props.children}
        </MuiIconButton>
      </span>
    </Tooltip>
  );
};

export default IconButton;
