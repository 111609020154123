import { ConfirmationDialog, IconButton, When } from '@airelogic/form-management/components';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import DeleteIcon from '@mui/icons-material/Delete';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  makeCanDeleteRowInGrid,
  makeSelectCanSplitGridSelector,
  makeSelectComponentCountInRow,
} from '../../StateManagement/Selectors/GridSelector';
import { makeIsRowHovered } from '../../StateManagement/Selectors/LayoutSelectionsSelector';
import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import { addRowToGrid, deleteRow, splitGrid } from '../../StateManagement/layoutSlice';
import { useStyles } from './RowOptions.styles';

interface Props {
  display?: boolean;
  gridId: string;
  rowId: string;
}

const RowOptions = ({ gridId, rowId, display = false }: Props) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const selectCanDeleteRowInGrid = useMemo(() => makeCanDeleteRowInGrid(gridId), [gridId]);
  const selectComponentCountInRow = useMemo(() => makeSelectComponentCountInRow(rowId), [rowId]);
  const selectCanSplitGrid = useMemo(
    () => makeSelectCanSplitGridSelector(gridId, rowId),
    [gridId, rowId],
  );

  const canDeleteRow = useAppSelector(selectCanDeleteRowInGrid);
  const canSplitGrid = useAppSelector(selectCanSplitGrid);
  const componentCount = useAppSelector(selectComponentCountInRow);

  const selectIsRowHovered = useMemo(() => makeIsRowHovered(rowId), [rowId]);
  const isRowHovered = useAppSelector(selectIsRowHovered);

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [isOptionsHovered, setIsOptionsHovered] = useState(false);

  const isHovered = isOptionsHovered || isRowHovered || display;

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    handleDeleteRow();
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const onAddRowAbove = () => {
    dispatch(addRowToGrid({ relativeRowId: rowId, gridId, insertPosition: 'above' }));
  };

  const onAddRowBelow = () => {
    dispatch(addRowToGrid({ relativeRowId: rowId, gridId, insertPosition: 'below' }));
  };

  const handleDeleteRow = () => {
    dispatch(deleteRow({ gridId, rowId }));
  };

  const onSplitGrid = () => {
    dispatch(splitGrid({ gridId: gridId, rowId: rowId }));
  };

  const onDeleteClicked = () =>
    componentCount > 0 ? setShowConfirmDelete(true) : handleDeleteRow();

  const handleHover = () => {
    setIsOptionsHovered(true);
  };

  const handleUnhover = () => {
    setIsOptionsHovered(false);
  };

  return (
    <Box width={'24px'} onMouseEnter={handleHover} onMouseLeave={handleUnhover}>
      <When condition={isHovered}>
        <Box
          display={'flex'}
          height={'100%'}
          flexDirection={'column'}
          data-testid={`rowOps-${rowId}`}
        >
          <Box>
            <IconButton onClick={onAddRowAbove} tooltipText="Add row above">
              <SkipPreviousIcon className={classes.rowUp} />
            </IconButton>
          </Box>
          <Box display={'flex'} flex={1} alignItems="center">
            <When condition={canDeleteRow}>
              <IconButton onClick={onDeleteClicked} tooltipText="Delete row">
                <DeleteIcon />
              </IconButton>
            </When>
          </Box>
          <Box display={'flex'} flex={1} alignItems="center">
            <When condition={canSplitGrid}>
              <IconButton onClick={onSplitGrid} tooltipText="Split grid">
                <CallSplitIcon />
              </IconButton>
            </When>
          </Box>
          <Box>
            <IconButton onClick={onAddRowBelow} tooltipText="Add row below">
              <SkipNextIcon className={classes.rowDown} />
            </IconButton>
          </Box>
        </Box>
      </When>
      <ConfirmationDialog
        data-testid={`deleteRowPrompt-${rowId}`}
        confirmationText={`Are you sure you want to delete the current row? ${componentCount} control(s) will be deleted.`}
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      />
    </Box>
  );
};
export default RowOptions;
