import { Box, FormControl, FormControlLabel, Switch, SwitchProps, Theme } from '@mui/material';
import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import InputTooltip from '../Tooltips/InputTooltip';
import { When } from '../When/When';

const useStyles = makeStyles<void, 'checked' | 'track' | 'focusVisible' | 'thumb'>()(
  (theme: Theme, _params, classes) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      [`&.${classes.checked}`]: {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        [`& + .${classes.track}`]: {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      [`&.${classes.focusVisible} .${classes.thumb}`]: {
        color: '#27C940',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
);

const BaseballCapSwitch = forwardRef(
  (props: SwitchProps & { label: string; tooltipText?: string }, ref): React.ReactElement => {
    const { classes } = useStyles();
    const { label, tooltipText, ...rest } = props;

    return (
      <FormControl margin="dense">
        <FormControlLabel
          control={
            <Box display="flex" flexDirection="row" alignItems="center">
              <Switch
                id={props.id ?? props.name}
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                inputRef={ref}
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                checked={props.value === true}
                {...rest}
              />
              <When condition={!!tooltipText}>
                <InputTooltip title={tooltipText}></InputTooltip>
              </When>
            </Box>
          }
          label={label}
        />
      </FormControl>
    );
  },
);

export default BaseballCapSwitch;
