import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import { ExistingForm } from './ExistingForm';
import Actions from './FormsTableActions';
import { useGetPreviousVersions } from './FormsTableQueries';

const PreviousVersionsTable = ({ formKey }: { formKey: string }) => {
  const { data } = useGetPreviousVersions({ formKey });

  const columns = useMemo<MRT_ColumnDef<ExistingForm>[]>(
    () => [
      {
        header: 'Version',
        accessorKey: 'version',
        size: 100,
        maxSize: 100,
      },
      {
        header: 'Title',
        accessorKey: 'title',
        size: 555,
      },
      {
        header: 'Delivery status',
        accessorKey: 'deliveryStatus',
        size: 100,
        maxSize: 100,
      },
      {
        header: 'Classification',
        accessorKey: 'classification',

        size: 100,
        maxSize: 100,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    rowCount: data?.length ?? 0,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnOrdering: false,
    enableHiding: false,
    enableMultiSort: false,
    enableExpandAll: false,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableTopToolbar: false,
    initialState: {
      showGlobalFilter: true,
      sorting: [{ id: 'version', desc: true }],
    },
    mrtTheme: () => ({
      baseBackgroundColor: '#fff',
    }),
    defaultColumn: {
      enableColumnFilter: false,
      enableGlobalFilter: true,
    },
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => <Actions existingForm={row.original} isLatestVersion={false} />,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 210,
        minSize: 210,
        maxSize: 210,
      },
    },
    muiTopToolbarProps: {
      sx: (theme) => ({
        padding: theme.spacing(1),
      }),
    },
  });

  return <MaterialReactTable data-testId="previousVersionTable" table={table} />;
};

export default PreviousVersionsTable;
