import { SingleCheckbox, TextInput, When } from '@airelogic/form-management/components';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '../../../Form';
import { makeSelectRepeatingGridIndex } from '../../../StateManagement/Selectors/GridSelector';
import { useAppSelector } from '../../../StateManagement/hooks';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
  gridId: string;
}

const BasicSettings = ({ fieldIndex, gridId }: Props) => {
  const selectRepeatingGridIndex = useMemo(() => makeSelectRepeatingGridIndex(gridId), [gridId]);
  const { register, control } = useFormContext<FormValues>();

  const repeatIndex = useAppSelector(selectRepeatingGridIndex);

  const displayMasterChildSetting = useWatch({
    control,
    name: `repeatSettings.${repeatIndex}.additionalSettings.displayAsMasterChild`,
  });

  return (
    <PanelWithHeader title="Basic Settings">
      <TextInput
        id="label"
        type="text"
        label="Control Label"
        multiline
        {...register(`controlSettings.${fieldIndex}.basicSettings.label`)}
      />
      <TextInput
        id="hint"
        type="text"
        label="Hint"
        multiline
        {...register(`controlSettings.${fieldIndex}.basicSettings.hint`)}
      />
      <When condition={displayMasterChildSetting}>
        <SingleCheckbox
          label="Show in master child summary"
          control={control}
          name={`controlSettings.${fieldIndex}.basicSettings.showInMasterChildSummary`}
        />
      </When>
    </PanelWithHeader>
  );
};

export default BasicSettings;
