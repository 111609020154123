import { Route, RouteProps } from 'react-router-dom';
import { Can } from '../AppContext';

import { BoundCanProps } from '@casl/react';
import { Except } from 'type-fest';
import { useAuth } from '../Authentication';
import { FormManagementAbility } from '../Authorisation/AbilityBuilder';
import AccessDenied from './AccessDenied';

type Props = Pick<RouteProps, 'exact' | 'path'> & {
  can: Except<
    BoundCanProps<FormManagementAbility>,
    'children' | 'not',
    { requireExactProps: true }
  >;
  children?: React.ReactNode;
};

export const AuthorisedRoute = (props: Props) => {
  const { exact, path, children } = props;
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <Route path={path} exact={exact}>
      <Can {...props.can}>{children}</Can>
      <Can not {...props.can}>
        <AccessDenied />
      </Can>
    </Route>
  );
};
