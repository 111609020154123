import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import FileSaver from 'file-saver';
import { MRT_ColumnFiltersState, MRT_PaginationState } from 'material-react-table';
import { useCallback } from 'react';
import { BuildingBlock } from './BuildingBlock';

interface ImportNewRequst {
  xml: string;
}

interface ImportRequest {
  orbeonId: string;
  newVersion: boolean;
}

interface PreviewRequest {
  orbeonId: string;
}

export const useGetAvailableBuildingBlocksQueryKey = 'buildingblocks-table';

type QueryParams = {
  globalFilter: string;
  sorting?: { id: string; desc: boolean };
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
};

export type AvailableBlocksResult = {
  totalItems: number;
  filteredItems: BuildingBlock[];
};

export const useGetAvailableBuildingBlocks = ({
  globalFilter,
  sorting,
  pagination,
  columnFilters,
}: QueryParams) => {
  return useQuery({
    queryKey: [
      useGetAvailableBuildingBlocksQueryKey,
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting?.id,
      sorting?.desc,
    ],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<AvailableBlocksResult>(
        '/internalapi/formbuilding/buildingblocks/available',
        {
          signal,
          params: {
            pageSize: pagination.pageSize,
            pageIndex: pagination.pageIndex,
            globalFilter: globalFilter ?? '',
            sortingColumnName: sorting?.id,
            sortDesc: sorting?.desc,
            columnFilters: columnFilters,
            'api-version': '2.0',
          },
        },
      );
      return data;
    },
  });
};

const usePostImport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: ImportRequest) => {
      await axios.post('/internalapi/formbuilding/buildingblocks/import', request);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [useGetAvailableBuildingBlocksQueryKey] });
    },
  });
};

const usePostPreview = () => {
  return useMutation({
    mutationFn: async (request: PreviewRequest) => {
      await axios.post('/internalapi/formbuilding/buildingblocks/preview', request);
    },
  });
};

const usePostUpload = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: ImportNewRequst) => {
      await axios.post('/internalapi/formbuilding/buildingblocks/upload', request);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [useGetAvailableBuildingBlocksQueryKey] });
    },
  });
};

const useRollback = (orbeonId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await axios.post(`/internalapi/formbuilding/buildingblocks/rollback/${orbeonId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [useGetAvailableBuildingBlocksQueryKey] });
    },
  });
};

interface ExportRequest {
  orbeonId: string;
  key: string;
}

const useDownloadBuildingBlock = () => {
  return useCallback((request: ExportRequest) => {
    FileSaver.saveAs(
      `/internalapi/formbuilding/buildingblocks/export/${request.orbeonId}`,
      `${request.key}.xml`,
    );
  }, []);
};

export { useDownloadBuildingBlock, usePostImport, usePostPreview, usePostUpload, useRollback };
