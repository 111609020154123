import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  borderRight: {
    borderRight: '1px solid #ccc',
    paddingTop: 0,
    paddingBottom: 0,
  },

  quill: {
    paddingBottom: 10,
    minWidth: '150px',
    '& .ql-editor': {
      minHeight: '100px',
    },
  },
}));

export const formats = ['bold', 'italic', 'underline', 'list', 'link'];
export const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  },
};

interface Props {
  id?: string;
  label?: string;
  required?: boolean;
  defaultValue?: string;
  error?: boolean;
  helperText?: string;
  onChange(): void;
}

const RichText = ({ defaultValue, error, helperText, onChange, required, label, id }: Props) => {
  const { classes } = useStyles();

  return (
    <FormControl required={required} error={error} fullWidth>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <ReactQuill
        id={id}
        className={classes.quill}
        theme={'snow'}
        formats={formats}
        modules={modules}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default RichText;
