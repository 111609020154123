import { ResourceKeyRegex } from '@airelogic/form-management/common/validations';
import { TextInput } from '@airelogic/form-management/components';
import { Box, Paper, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RefDataFormValues, ReferenceDataItem } from '../Types';
import { useStyles } from './ReferenceDataPageEdit.styles';
import ReferenceDataPageTable from './ReferenceDataPageTable';

interface Props {
  isNew: boolean;
}

const blankRefData: ReferenceDataItem = {
  displayName: '',
  code: '',
  stopCondition: false,
  stopConditionText: '',
  optionIsExclusive: false,
  deprecated: false,
  filterGroups: '',
  isNew: true,
};

const ReferenceDataPageEdit = ({ isNew }: Props) => {
  const { classes } = useStyles();

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<RefDataFormValues>();

  const { fields, append, move, remove, insert } = useFieldArray({
    control,
    name: `referenceData`,
  });

  const onAppend = () => {
    append(blankRefData);
  };

  return (
    <Paper elevation={3} className={classes.root}>
      <Box display="flex" flexDirection="row" className={classes.paperHeader}>
        <Typography variant="h6" className={classes.headerText} aria-label="page title">
          Reference Data
        </Typography>
      </Box>

      <Box width="50%">
        <TextInput
          id="key"
          label="Key"
          fullWidth
          {...register('key', {
            required: { value: true, message: 'Must not be empty' },
            pattern: {
              value: ResourceKeyRegex,
              message: 'Invalid Key',
            },
          })}
          required
          error={errors?.key !== undefined}
          helperText={errors?.key?.message}
          disabled={!isNew}
          tooltip={
            !isNew
              ? 'The key cannot be changed once created.'
              : 'Identifies this specific reference data.'
          }
        />
      </Box>

      <ReferenceDataPageTable
        fields={fields}
        remove={remove}
        addItem={onAppend}
        move={move}
        insert={insert}
      />
    </Paper>
  );
};

export default ReferenceDataPageEdit;
