import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import {
  getAllControls,
  getClipboard,
  getControl,
  getControlForCell,
  getControlsForGrid,
  getControlsInRow,
  getGridById,
  getLocalState,
  getNextCell,
  getSelectedCell,
  getSelectedControl,
} from '../localSelectors';
import { Cell } from '../types';

export const selectAllControls = createSelector(getLocalState, getAllControls);
export const selectClipboard = createSelector(getLocalState, getClipboard);

export const makeSelectControlForCell = (cell: Cell) =>
  createSelector([getLocalState], (layout) => getControlForCell(layout, cell));

export const makeSelectControlIdsForGrid = (gridId: string) =>
  createSelector([getLocalState], (layout) => getControlsForGrid(layout, gridId).map((c) => c.id));

export const makeSelectControlsInRow = (rowId: string) =>
  createSelector([getLocalState], (layout) => getControlsInRow(layout, rowId));

export const makeSelectControl = (controlId: string) =>
  createSelector([getLocalState], (layout) => getControl(layout, controlId));

export const selectSelectedControl = createSelector(getLocalState, getSelectedControl);

export const selectAllControlIdsAndTypes = createSelector(
  selectAllControls,
  (controls) => controls.map((c) => ({ id: c.id, type: c.type })),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

//This is sorta duplicated in controlReducer but that also creates a row. Look at refactoring later
export const selectCanAddControl = createSelector(getLocalState, (state) => {
  const selectedCell = getSelectedCell(state);
  if (selectedCell) {
    const existingControl = getControlForCell(state, selectedCell);
    if (existingControl) {
      const nextCell = getNextCell(state, selectedCell, existingControl.cellSpan);
      if (nextCell) {
        return getControlForCell(state, nextCell) === undefined;
      } else {
        return true;
      }
    }
    return true;
  }

  return false;
});

export const makeSelectCanIncreaseCellSpan = (cell: Cell) =>
  createSelector([getLocalState], (state) => {
    const existingControl = getControlForCell(state, cell);
    if (existingControl) {
      const grid = getGridById(state, cell.gridId);
      if (grid.columnIds.length < existingControl.cellSpan) {
        return false;
      }

      const nextCell = getNextCell(state, cell, existingControl.cellSpan);
      if (nextCell) {
        return getControlForCell(state, nextCell) === undefined;
      }
    }
    return false;
  });
